/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import VvVideo from '../assets/videos/vv-new.mp4';
import GridBlock from '../components/gridBlock';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ImgDisplay from '../components/imgDisplay';
import TopMenu from '../components/topMenu';
import ImgLogoDisplay from '../components/imgLogoDisplay';

const blockBg = img =>
  `url(${img}) center / cover no-repeat fixed padding-box content-box black`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query connectTwoBCSeSearch {
      whiterLogo: file(relativePath: { eq: "VV.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
            }
        }
      }
          connector: file(relativePath: { eq: "cars/GROW.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    
  const blockCSS = 'flex flex-auto group w-full relative h-1/8 border-0';
  const colorCSS =
    'transition h-full w-full absolute opacity-10 group-hover:opacity-30';
  return (
      
    <Layout to="../why-vivo" label="WHY VIVO VIBE" showMenuIcon className="">
      <SEO title="Vivo Vibe" />
      <section className="h-screen w-screen">
        <div className="w-screen h-screen fixed">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full fixed"
          >
            <source src={VvVideo} type="video/mp4" />
          </video>
          <div className="w-full h-full overlay fixed"></div>
        </div>
        <div className="relative flex h-screen w-screen flex-col fixing-stuff md:px-40 py-10">
            <ImgLogoDisplay
                        image={data.connector}
                        >
                        </ImgLogoDisplay>
      
      <div class="pt-5 md:pt-16 grid grid-cols-1 md:grid-cols-2 gap-4 text-white text-left">

        
    
      </div>
        </div>
      </section>

      </Layout>
  );
};

export default IndexPage;
